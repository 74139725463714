/**
 * Import bootstrap stuff
 */
require('./bootstrap');

/**
 * Chart.js
 */
import Chart from 'chart.js';

/**
 * choice.js
 */
import Choices from 'choices.js';

let chConf = {
    removeItemButton: true,
    itemSelectText: '',
    placeholder: true,
};

if (document.getElementById('status') || document.getElementById('country')) {
    new Choices('#status', chConf);
    new Choices('#country', chConf);
}
